import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { PageRenderProps } from './types'
import './styles.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setAuthRouteAuth } from '../../../modules/auth/reducers/authReducer'
import { validationRoutesKeys } from '../../../routes'
import RenderRoutePage from '../../../routes/components/RenderRoutePage'
import Header from '../Header'
import Main from '../Main'
import Footer from '../Footer'
import createClassName from '../../../utils/createClassName'
import ScrollReset from '../../ScrollReset'
import FlashBar from '../../FlashBar'

const PageRender: React.FC <PageRenderProps> = ({ children, pageKey, ...props }) => {

    const dispatch = useAppDispatch()
    const { routeAuth, userData } = useAppSelector(s => s.auth)
    const [userValidation, setUserValidation] = useState(true)

    const wrapperClasses = createClassName([props.type ?? 'default'])

    useEffect(() => {
        document.title = props.title ?? 'Title Not Provided'
    }, [props.title, props.path])

    useEffect(() => {
        if(!!props.userVerification && !userData) setUserValidation(false)
        else if(!!props.authVerification) dispatch(setAuthRouteAuth(validationRoutesKeys.includes(props.path!.replace('/', ''))))
        else dispatch(setAuthRouteAuth(true))
    }, [dispatch, props.userVerification, props.authVerification, props.path, userData])

    return(

        <div id = "wrapper" className = {wrapperClasses} data-page = {props.path}>
            <ScrollReset />
            {(!userValidation && <Navigate to = "/login" />) || ((routeAuth && (
                (['center', 'error'].includes(props.type ?? 'default') && (children ?? <RenderRoutePage name = {pageKey!} />)) || (
                    <>
                        {<FlashBar />}
                        <Header />
                        <Main name = {props.name ?? ''}>
                            {children ?? <RenderRoutePage name = {pageKey!} />}
                        </Main>
                        <Footer />
                    </>
                )
            )) || <Navigate to = "/error/http-401" />)}
        </div>

    )

}

export default React.memo(PageRender, (prevProps, nextProps) => {
    return (
        prevProps.path === nextProps.path &&
        prevProps.type === nextProps.type &&
        prevProps.userVerification === nextProps.userVerification &&
        prevProps.authVerification === nextProps.authVerification
    )
})