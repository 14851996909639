import { RouteType } from "../../../routes/interfaces"

const homeRoutes: RouteType[] = [
    {
        key: 'home',
        path: 'home',
        title: 'Home',
        userVerification: true,
    }
]

export default homeRoutes