import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../../modules/auth/reducers/authReducer'
import themeReducer from '../../modules/theme/reducers/themeReducer'

const store = configureStore({
    reducer: {
        auth: authReducer,
        theme: themeReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store