import React from 'react'
import Section from '../../../../components/Page/Section'

const Home: React.FC = () => {

    return(

        <>
            <Section>
                <h1>Welcome to React JS</h1>
                <h1>Boilerplate</h1>
            </Section>
        </>

    )

}

export default Home