import { RouteType } from "../../../routes/interfaces"

const authRoutes: RouteType[] = [
    {
        key: 'login',
        path: 'login',
        title: 'Login',
        type: 'center',
    },
    {
        key: 'register',
        path: 'register',
        title: 'Register',
        type: 'center',
    },
    {
        key: 'forgot-password',
        path: 'forgot-password',
        title: 'Forgot Password',
        type: 'center',
    }
]

export default authRoutes