import { reactLocalStorage as ls } from 'reactjs-localstorage'
import { UserData } from '../interfaces/UserData'
import { setAuthLogin } from '../reducers/authReducer'
import info from '../../../utils/info'

export default function getUser(dispatch: Function){
    try {
        const localUser = ls.getObject('user') as UserData | undefined
        if(!!localUser && !!localUser.email) dispatch(setAuthLogin(localUser))
        else throw new Error('User not found')
    } catch (error) {
        info.error('getUser',error)
    }
}