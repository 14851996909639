import info from "../../../utils/info"
import { LoginFormValues } from "../pages/Login/types"
import { setAuthLogin } from "../reducers/authReducer"

export default async function handleLogin(dispatch: Function, setRedirect: Function, values: LoginFormValues){
    try {
        console.log(values)
        dispatch(setAuthLogin(values))
        setRedirect(true)
    } catch (error) {
        info.error("handleLogin",error)
    }
}