import React from 'react'
import { MainProps } from './types'
import { firstLetterToUpperCase } from '../../../utils/format'

const Main: React.FC <MainProps> = ({ children, name }) => {
    return(
        <main id = {`main${firstLetterToUpperCase(name)}`}>
            <div id = "mainContainer">{children}</div>
        </main>
    )
}

export default Main