import React from 'react'
import './styles.scss'
import HeaderContainer from './Container'
import HeaderIndicator from './Indicator'

const Header: React.FC = () => {

    return(

        <HeaderContainer>
            <div id = "headerNav">
                
            </div>
            <HeaderIndicator />
        </HeaderContainer>

    )

}

export default Header