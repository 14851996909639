import React, { useEffect, useState } from 'react'
import createClassName from '../../../../utils/createClassName'

const HeaderContainer: React.FC = ({ children }) => {

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        document.addEventListener('scroll', () => setIsSticky(document.querySelector('header')!.getBoundingClientRect().top === 0))
        return () => {document.removeEventListener('scroll', () => {})}
    }, [])

    return(

        <header className = {createClassName('', [isSticky && 'fixed'])}>
            <div id = "headerContainer">{children}</div>
        </header>

    )

}

export default HeaderContainer