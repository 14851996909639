import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import createClassName from '../../utils/createClassName'

const FlashBar: React.FC = () => {
    // PASS STATUS

    const containerClasses = createClassName('status', ['success'])

    return(

        <div id = "flashBarContainer" className = {containerClasses}>
            <h1>Some Text Special Here!!!</h1>
            &nbsp;
            <Link to = "">Take a look</Link>
        </div>

    )

}

export default FlashBar