import React from 'react'
import Typewriter from 'typewriter-effect'
import './styles.scss'

const Preview: React.FC = () => {

    return(

        <>
            <h1>
                <Typewriter
                    options = {{
                        autoStart: true,
                        loop: true,
                    }}
                    onInit = {typewriter => {
                        typewriter.typeString('Em breve...')
                        .pauseFor(3000)
                        .deleteAll()
                        typewriter.typeString('Um pequeno resumo do meu trabalho')
                        .pauseFor(3000)
                        .deleteAll()
                        .pauseFor(3000)
                        .start();
                    }}
                />
            </h1>
        </>

    )

}

export default Preview