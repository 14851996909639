import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ErrorPageProps } from './types'
import PageRender from '../../../../components/Page/Render'
import ErrorMessage from '../../components/ErrorMessage'
import { ErrorMessageProps } from '../../components/ErrorMessage/types'
import setError from '../../scripts/setError'

const Error: React.FC <Partial<ErrorPageProps>> = props => {

    const { idError } = useParams()
    const [errorText] = useState<ErrorMessageProps>(setError(idError ?? props.idError))

    return (

        <PageRender title = {errorText.title} type = "error" path = "/error" name = "error">
            <ErrorMessage {...errorText} />
        </PageRender>

    )

}

export default Error