import React from 'react'
import Form from './components/Form'

const Login: React.FC = () => {

    return(

        <>
            <Form />
        </>

    )

}

export default Login