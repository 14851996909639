import { RouteType } from "./interfaces"
import authRoutes from "../modules/auth/routes"
import homeRoutes from "../modules/home/routes"
import errorRoutes from "../modules/error/routes"
import commonRoutes from "../modules/common/routes"

const routes: RouteType[] = [
    ...homeRoutes,
    ...authRoutes,
    ...errorRoutes,
    ...commonRoutes,
]

const routesKeys = routes.reduce((acc: string[], route) => {
    acc.push(route.key)
    if (route.subRoutes) route.subRoutes.forEach(subRoute => acc.push(subRoute.key))
    return acc
}, [])

const validationRoutesKeys = ['']

export { routesKeys, validationRoutesKeys }
export default routes