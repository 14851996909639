import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { reactLocalStorage as ls } from 'reactjs-localstorage'
import { UserData } from "../interfaces/UserData"

interface State {
    userData: UserData | null
    loading: boolean
    isLogged: boolean
    routeAuth: boolean
}

const initialState: State = {
    userData: null,
    loading: true,
    isLogged: false,
    routeAuth: true,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthLogin: (state, action: PayloadAction<UserData>) => {
            state.userData = action.payload
            state.loading = false
            state.isLogged = true
            ls.setObject('user',action.payload)
        },
        setAuthLogout: (state) => {
            state.userData = null
            state.isLogged = false
            ls.remove('user')
            window.location.reload()
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAuthIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLogged = action.payload
        },
        setAuthRouteAuth: (state, action: PayloadAction<boolean>) => {
            state.routeAuth = action.payload
        },
    }
})

export const { setAuthLogin, setAuthLogout, setAuthLoading, setAuthIsLoggedIn, setAuthRouteAuth } = authSlice.actions
export default authSlice.reducer