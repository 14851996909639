import React, { Fragment } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import { useAppDispatch } from '../../../store/hooks'
import routes from '../..'
import PageRender from '../../../components/Page/Render'
import Error from '../../../modules/error/pages/Error'
import getUser from '../../../modules/auth/scripts/getUser'
import configTheme from '../../../modules/theme/scripts/configTheme'

const RenderRoutes: React.FC = () => {

    const dispatch = useAppDispatch()

    configTheme(dispatch, true)
    getUser(dispatch)
    
    return(

        <BrowserRouter>
            <Routes>
                <Route index element = {<Navigate to = "preview" />} />
                {routes.map((route, index) => (
                    <Fragment key = {index}>
                        {(route.subRoutes && (
                            <Route path = {route.path}>
                                {route.subRoutes.map((subRoute, subIndex) => (
                                    <Route key = {subIndex} path = {subRoute.path} element = {<PageRender {...subRoute} pageKey = {subRoute.key} />} />
                                ))}
                            </Route>
                        )) || <Route path = {route.path} element = {<PageRender {...route} pageKey = {route.key} />} />}
                    </Fragment>
                ))}
                <Route path = "*" element = {<Error idError = "http-404" />} />
            </Routes>
        </BrowserRouter>

    )

}

export default React.memo(RenderRoutes)