import React, { useState } from 'react'
import { Formik, Form as FormikForm, Field } from 'formik'
import { useAppDispatch } from '../../../../../../store/hooks'
import handleLogin from '../../../../scripts/handleLogin'
import formValues from './values'
import ChangeRoute from '../../../../components/ChangeRoute'

const Form: React.FC = () => {

    const dispatch = useAppDispatch()
    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <ChangeRoute />}
            <Formik
                initialValues = {formValues()}
                onSubmit = {async values => await handleLogin(dispatch, setRedirect, values)}
            >
                <FormikForm style = {{display: 'flex', flexDirection: 'column'}}>
                    <Field type = "text" name = "email" />
                    <Field type = "password" name = "password" />
                    <button type = "submit">Entrar</button>
                </FormikForm>
            </Formik>
        </>

    )

}

export default Form