import { RouteType } from "../../../routes/interfaces"

const commonRoutes: RouteType[] = [
    {
        key: 'preview',
        path: 'preview',
        title: 'Diguinho LNS',
        type: 'center',
    }
]

export default commonRoutes