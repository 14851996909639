import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import './styles/vars.scss'
import './styles/app.scss'
import store from './store/store'
import RenderRoutes from './routes/components/RenderRoutes'

const App: React.FC = () => {
    
    return(

        <>
            <ReduxProvider store = {store}>
                <RenderRoutes />
            </ReduxProvider>
        </>

    )

}

export default App